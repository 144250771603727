import React from "react"
import Layout from "../components/layout/Layout"
import HeroImage from "../components/HeroImage"
import SEO from "../components/SEO"
// import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby'
import { ImHome } from "react-icons/im"
import { Link } from "gatsby"


const About = (props) => {
    const {about} = useStaticQuery(graphql`
        query {
            about: file(relativePath: {eq: "page/about/index.md"}) {
                childMarkdownRemark {
                  html
                  frontmatter {
                    title
                    seotitle
                    seometadesc
                    featureimagealt
                    featureimage {
                      childImageSharp {
                        fluid(maxWidth: 4000, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                      extension
                      publicURL
                    }
                  }
                }
              }
        }
    `)

    const aboutdata = about.childMarkdownRemark

    return (
        <>
            <Layout location={props.location}>
                <SEO title={ aboutdata.frontmatter.seotitle } description={aboutdata.frontmatter.seometadesc} image={aboutdata.frontmatter.featureimage.publicURL}/>
                <HeroImage markdown={ aboutdata } />
                <section className="container mx-auto">
                    <div className="text-left px-4 pt-4 pb-4">
                      <ul className="text-left text-xs md:text-base">
                        <li className="inline-block">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-1">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block"><span>·</span></li>
                        <li className="inline-block">
                          <span className="px-1">{aboutdata.frontmatter.seotitle}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="mx-auto px-2 lg:flex lg:w-2/3">
                        <div className="p-6">
                            <h1>{aboutdata.frontmatter.title}</h1>
                            <div className="text-left lg:text-justify cmscontent" dangerouslySetInnerHTML={{ __html: aboutdata.html }} />
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default About